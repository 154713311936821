const AppMenuHeader = () => {
  return (
    <div className="app-menu-header">
      <div className="app-menu-header-logo"></div>
      <div className="app-menu-header-content">
        <div className="logo-text">KodTicaret v.5.0</div>
        <div className="logo-description">Yeni Nesil Bayilik Sistemi</div>
      </div>
    </div>
  );
};

export default AppMenuHeader;
