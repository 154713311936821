import React from "react";
import "./styles.scss";
import AppMenuFooter from "./AppMenuFooter";
import AppMenuHeader from "./AppMenuHeader";
import AppMenuItems from "./AppMenuItems";

const AppMenu = () => {
  return (
    <div className="app-menu">
      <AppMenuHeader />
      <AppMenuItems />
      <AppMenuFooter />
    </div>
  );
};

export default AppMenu;
