import React from "react";
import "./styles.scss";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import PowerSettingsNewOutlinedIcon from "@mui/icons-material/PowerSettingsNewOutlined";
import { Button } from "@mui/material";
import { setLogout } from "../../../redux/features/auth/authSlice";

const AppHeader = () => {
  return (
    <div className="app-header">
      <AppHeaderWelcome />
      <AppHeaderShortcuts />
    </div>
  );
};

const AppHeaderWelcome = () => {
  const user = useSelector((state) => state.auth.user);

  return (
    <div className="app-header-welcome">
      <div className="app-header-welcome-logo"></div>
      <div className="app-header-welcome-content">
        <div className="profile-name"> {user.name}</div>
        <div className="app-header-welcome-text">{user.email}</div>
      </div>
    </div>
  );
};

const AppHeaderShortcuts = () => {
  const dispatch = useDispatch();

  const shortcuts = [
    {
      key: "orderPrint",
      title: "Sipariş Yazdır",
      icon: <PrintOutlinedIcon />,
      link: "/",
    },
    {
      key: "showSite",
      title: "Siteyi Görüntüle",
      icon: <VisibilityOutlinedIcon />,
      link: "/",
    },
  ];
  return (
    <div className="app-shortcuts">
      {shortcuts.map((item) => {
        return (
          <Link
            to={item.link}
            key={item.key}
            className={`app-shortcuts-item ${item.key}`}
          >
            <div className="app-shortcuts-item-icon">{item.icon}</div>
            {item.title && (
              <div className="app-shortcuts-item-title">{item.title}</div>
            )}
          </Link>
        );
      })}
      <Button
        className={`app-shortcuts-item signOut`}
        onClick={() => dispatch(setLogout())}
      >
        <div className="app-shortcuts-item-icon">
          <PowerSettingsNewOutlinedIcon />
        </div>
      </Button>
    </div>
  );
};

export default AppHeader;
