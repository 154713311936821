import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import InsertChartOutlinedRoundedIcon from "@mui/icons-material/InsertChartOutlinedRounded";

import { IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedMenu } from "../../../redux/features/shared/layoutSlice";
import { useNavigate } from "react-router-dom";

const AppMenuItems = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedMenu = useSelector((state) => state.layout.selectedMenu);

  const menuItems = [
    {
      key: "dashboard",
      title: "Güncel Durum",
      icon: <DashboardOutlinedIcon />,
      link: "/",
    },
    {
      key: "products",
      title: "Ürün Yönetimi",
      icon: <Inventory2OutlinedIcon />,
      link: "/products",
    },
    {
      key: "orders",
      title: "Sipariş Yönetimi",
      icon: <ShoppingBagOutlinedIcon />,
      link: "/orders",
    },
    {
      key: "users",
      title: "Bayi Yönetimi",
      icon: <AccountBoxOutlinedIcon />,
      link: "/users",
    },
    {
      key: "reports",
      title: "Raporlar",
      icon: <InsertChartOutlinedRoundedIcon />,
      link: "/reports",
    },
    {
      key: "settings",
      title: "Ayarlar",
      icon: <SettingsOutlinedIcon />,
      link: "/settings",
    },
  ];

  return (
    <div className="app-menu-items">
      {menuItems.map((menu) => {
        return (
          <IconButton
            onClick={() => {
              dispatch(setSelectedMenu(menu.key));
              navigate(menu.link);
            }}
            key={menu.key}
            className={`app-menu-item ${
              selectedMenu === menu.key ? "active" : ""
            }`}
          >
            <div className="app-menu-content">
              <div className="app-menu-icon">{menu.icon}</div>
              <div className="app-menu-text">{menu.title}</div>
            </div>
            {selectedMenu === menu.key && (
              <ChevronRightOutlinedIcon className="menu-arrow-right" />
            )}
          </IconButton>
        );
      })}
    </div>
  );
};

export default AppMenuItems;
