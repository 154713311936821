import "react-confirm-alert/src/react-confirm-alert.css";
import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import Loading from "../../components/customLoading/Loading";
const Dashboard = React.lazy(() => import("./dashboard/dashboard"));
const Products = React.lazy(() => import("./products"));
const Users = React.lazy(() => import("./users"));
const Orders = React.lazy(() => import("./orders"));

const routes = [
  {
    key: "dashboard",
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    key: "products",
    path: "/products/*",
    element: <Products />,
  },
  {
    key: "orders",
    path: "/orders/*",
    element: <Orders />,
  },
  {
    key: "users",
    path: "/users/*",
    element: <Users />,
  },
];
const AppViews = (props) => {
  return (
    <div className="kod-box">
      <Suspense fallback={<Loading />}>
        <Routes>
          {routes.map((route) => (
            <Route key={route.key} path={route.path} element={route.element} />
          ))}
          <Route path={`*`} element={<Dashboard />} />
        </Routes>
      </Suspense>
    </div>
  );
};

export default AppViews;
