import React from "react";
import AuthView from "../../views/auth-views";
import { Grid } from "@mui/material";

const AuthLayout = () => {
  return (
    <div className="auth-container">
      <Grid
        container
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          container
          item
          width={400}
          className="login-container auth-kod-box"
        >
          <div className="logo-content">KodTicaret v.5.0</div>
          <Grid container item padding={3} rowSpacing={2}>
            <AuthView />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default AuthLayout;
