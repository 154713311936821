import React from "react";
import AppViews from "../../views/app-views/appViews";
import AppHeader from "./app-header";
import AppMenu from "./app-menu";

const AppLayout = (props) => {
  return (
    <div className="app-container">
      <div className="app-top-header">
        <span>
          🚀 Yeni Nesil Bayilik Sistemi 💡 Yenilikçi çözümlerle, işinizi bir
          adım öteye taşıyın!
        </span>
      </div>
      <div className="app-body">
        <AppMenu />
        <div className="app-body-content">
          <AppHeader />
          <AppViews />
        </div>
      </div>
    </div>
  );
};

export default AppLayout;
