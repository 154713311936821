import "./styles.scss";

const Loading = () => {
  return (
    <div className="kod-loading-bg">
      <div className="kod-loading-box">
        <div className="loader"></div>
        <div className="kod-loading-text">Lütfen Bekleyiniz!!!</div>
      </div>
    </div>
  );
};

export default Loading;
