import { HashRouter as Router, Route, Routes } from "react-router-dom";
import Views from "./views";
import "./utils/functions";
import React from "react";
import axios from "axios";
import Loading from "./components/customLoading/Loading";

function App() {
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    const requestInterceptor = axios.interceptors.request.use((config) => {
      setLoading(true);
      return config;
    });

    const responseInterceptor = axios.interceptors.response.use(
      (response) => {
        setLoading(false);
        return response;
      },
      (error) => {
        setLoading(false);
        return Promise.reject(error);
      }
    );
    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, []);

  return (
    <>
      {loading && <Loading />}
      <Router>
        <Routes>
          <Route path="*" element={<Views />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
