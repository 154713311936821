import React, { Suspense } from "react";
import "../../styles/auth.scss";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import Loading from "../../components/customLoading/Loading";
// import AuthLoading from "components/shared-components/page-loading-components/auth-loading";

const Login = React.lazy(() => import("./login"));

const routes = [
  {
    key: "login",
    path: "/login",
    element: <Login />,
  },
  //   {
  //     key: "login-by-guid",
  //     path: "/login-by-guid",
  //     element: <LoginByGuid />,
  //   },
  //   {
  //     key: "forgot-password",
  //     path: "/forgot-password",
  //     element: <ForgotPassword />,
  //   },
  //   {
  //     key: "reset-password",
  //     path: "/reset-password",
  //     element: <ResetPassword />,
  //   },
  //   {
  //     key: "activation",
  //     path: "/activation",
  //     element: <Activation />,
  //   },
];

const AuthView = () => {
  const token = useSelector((state) => state.auth.accessToken);

  React.useEffect(() => {
    if (token) {
      window.location.href = "/";
    }
  }, [token]);

  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        {routes.map((route) => (
          <Route key={route.key} path={route.path} element={route.element} />
        ))}
        <Route path={`*`} element={<Login />} />
      </Routes>
    </Suspense>
  );
};

export default AuthView;
