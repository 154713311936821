import React from "react";
import { useSelector } from "react-redux";

const AppMenuFooter = () => {
  const user = useSelector((state) => state.auth.user);

  return (
    <div className="app-menu-footer">
      <div className="app-menu-footer-logo"></div>
      <div className="app-menu-footer-content">
        <div className="profile-name"> {user.name}</div>
        <div className="profile-email">{user.email}</div>
      </div>
    </div>
  );
};
export default AppMenuFooter;
